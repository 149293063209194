import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ApiNames from "./constants/ApiNames";
import AwarenessScreen from "./screens/AwarenessScreen";
const LandingRoutes = (props) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    initalizeToken();
  }, []);

  const initalizeToken = async () => {
    try {
      let data = {
        username: "hakunaMatata",
        password: "hakunaMatata",
      };
      let url = process.env.REACT_APP_BASEURL;
      if (window.location.protocol === "http:") {
        url = url.replace("https", "http");
      }
      let response = await fetch(`${url}${ApiNames.generateToken}`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let jsonResponse = await response.json();
      if (jsonResponse.jwttoken) {
        setToken(jsonResponse.jwttoken);
      }
    } catch (error) {
      console.log("authenticateToken error :", error);
    }
  };
  return (
    <Routes>
      <Route path="/awareness" element={<AwarenessScreen token={token} />} />
    </Routes>
  );
};

export default LandingRoutes;
